<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between align-items-center mb-1">
        <h1 class="font-weight-bolder mb-0">
          {{ $t('biddings') }}
        </h1>
        <!-- <div>
          <b-form-select v-model="quoteStatus">
            <b-form-select-option :value="null">
              {{ $t('biddings') }}
            </b-form-select-option>
            <b-form-select-option value="pending">
              Pendientes
            </b-form-select-option>
            <b-form-select-option value="buyout">
              {{ $t('sells') }}
            </b-form-select-option>
            <b-form-select-option value="awaiting_customer_response">
              Cotizada
            </b-form-select-option>

            <b-form-select-option value="bidding">
              En subasta
            </b-form-select-option>
            <b-form-select-option value="awaiting_bid_approval">
              Subasta cerrada
            </b-form-select-option>
            <b-form-select-option value="bid_rejected">
              Subasta denegada
            </b-form-select-option>
            <b-form-select-option value="bid_expired">
              Subasta expirada
            </b-form-select-option>
            <b-form-select-option value="bid_approved">
              Subasta aprobada
            </b-form-select-option>
          </b-form-select>
        </div> -->
      </b-col>
    </b-row>
    <b-row v-if="quotes.length > 0">
      <b-col>
        <quotes-table :quotes="quotes" />
      </b-col>
    </b-row>
    <b-row v-if="quotes.length === 0">
      <b-col>
        <h4 class="display-4">
          No hay Subastas
        </h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import QuotesTable from '@/components/pricing/QuotesTable.vue'

export default {
  components: {
    QuotesTable,
  },

  data() {
    return {
      quoteStatus: null,
    }
  },

  computed: {
    ...mapGetters('quotes', ['quotes']),
  },

  watch: {
    quoteStatus(status) {
      if (status === null) {
        this.getBiddingQuotes({ by_supplier_branch: this.$route.params.id })
      }
      if (status === 'buyout') {
        this.getQuotesWon({ winnerBranch: this.$route.params.id })
      }
    },
  },

  created() {
    this.getBiddingQuotes({ by_supplier_branch: this.$route.params.id })
  },
  destroyed() {
    this.clearQuotes()
  },

  methods: {
    ...mapActions('quotes', ['getBiddingQuotes', 'getQuotesWon']),
    ...mapMutations('quotes', {
      clearQuotes: 'CLEAR_QUOTES',
    }),
  },
}
</script>

<style></style>
